import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private uri = `${this.settingsServe.API_BASE_URL}/taskdetails`;

  constructor(
    private settingsServe: SettingsService,
    private http: HttpClient
  ) { }



  getTaskList(params: any): Promise<any> {
    return this.http.get<any>(this.uri, { params }).toPromise();
  }

  createTask(params: any): Promise<any> {
    return this.http.post<any>(this.uri, params).toPromise();
  }

  updateTask(id: any, params: any): Promise<any> {
    return this.http.put<any>(`${this.uri}/${id}`, params).toPromise();
  }
  getTaskById(id: any): Promise<any> {
    return this.http.get<any>(`${this.uri}/${id}`).toPromise();
  }

  uploadAvtarImage(image: File): Promise<any> {
    const data = new FormData();
    data.append('image', image);
    return this.http.post(`${this.uri}/upload`, data).toPromise();
  }

  getUserTaskList(params: any): Promise<any> {
    return this.http.get<any>(`${this.uri}/getUsersTask`, { params }).toPromise();
  }

  getUserTaskBoard(params: any): Promise<any> {
    return this.http.get<any>(`${this.uri}/getUsersTaskBoard`, { params }).toPromise();
  }
}
