import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddButtonComponent } from './add-button/add-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AddButtonComponent],
  imports: [
    CommonModule, MatIconModule, MatButtonModule
  ],
  exports: [
    AddButtonComponent,
  ]
})
export class AddButton { }
