<div class="modal-dialog">
    <h3 class="font-weight-bold font-size-20 text-center">LIVE GIFT DETAILS</h3>
    <div class="row justify-content-center">
        <div class=" col-lg-6">

            <img class="" height="200" width="200"
            [src]="data.image"
                alt="">


        </div>
        <div class="col-lg-6">

            <div class="row">
                <div class="col-12">
                    <p class="font-size-20 font-weight-bold">{{data.name}} </p>
                </div>
                <div class="col-12">
                    <p class="font-size-20 ">{{data.description}}</p>
                </div>
                <!-- <div class="col-12">
                    <p class="font-size-20 font-weight-bold">$45000 </p>
                </div> -->


            </div>

        </div>

    </div>
</div>