<div>
    <p class="font-weight-bold text-center font-18">STREAM ID : {{data.streamId}}</p>
    <div>
        <p class="text-center mt-2">You are dismissing or revoke these violations. Are you sure?</p>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-lg-4">
            <button type="button" class="cancelBtn text-white" mat-raised-button color="primary"
                (click)="cancelClickEvent()">Cancel</button>
        </div>
        <div class="col-lg-4">
            <button type="button" class="yesBtn text-white" mat-raised-button (click)="dismissViolation()"
                cdkFocusInitial>Yes</button>
        </div>
    </div>
</div>