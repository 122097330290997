import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dismiss',
  templateUrl: './dismiss.component.html',
  styleUrls: ['./dismiss.component.css']
})
export class DismissComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DismissComponent>,
  ) { }

  streamId = this.data.streamId;
  caseId = this.data.caseId;
  ngOnInit(): void {

    console.log('stream id : ', this.streamId);
    console.log('caseId : ', this.caseId);

  }

  dismissViolation() {
    this.dialogRef.close({streamId: this.streamId, caseId: this.caseId});
  }

  cancelClickEvent() {
    this.dialogRef.close();
  }

}
